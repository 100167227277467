import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cross from "./cross-icon";

const LoginContent = ({ onButtonClick }: { onButtonClick?: () => void }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isVendor = searchParams.has("vendor");
  const [isFullWidth, setIsFullWidth] = useState(false);
  const handleClick = () => {
    setIsFullWidth(!isFullWidth);
    if (onButtonClick) onButtonClick();
  };
  return (
    <>
      <div
        style={{transition: 'width 1s'}} className={`login_lhs ${
          isFullWidth ? "full-width slide-out" : "w_50 slide-in"
        }`}
      >
        {isVendor ? (
          <>
            {" "}
            <figure
              className={`logo2_mg ${isFullWidth ? "margin-unset" : ""} ${
                isVendor ? "vendor" : "corporate"
              }`}
              onClick={() => navigate("/")}
            >
              <img src="./assets/images/logo.svg" alt="logo"></img>
            </figure>
            <div className="mobile-res-only">
              <figure onClick={() => navigate("/")} className={`crs_icn ${isFullWidth ? "fade-in" : ""}`}>
                <img src="./assets/images/login-landing-cross.svg" alt="logo" />
              </figure>
              <div className="top">
                <span className="sub-heading">Switch to desktop view for a more optimized user experience</span>
                <p className="paragraph">
                  At Esqyre, we partner with luxury vehicle vendors who
                  share our passion for quality and exclusivity. Expand your
                  clientele and reach new heights by listing your vehicles
                  with us
                </p>
              </div>
            </div>
            {/* {isFullWidth ? ( */}
              <>
                {/* <figure
                  className="logo2_mg margin-unset"
                  onClick={() => navigate("/")}
                >
                  <img src="./assets/images/logo.svg" alt="logo"></img>
                </figure> */}
                <div className={`vendor-container-full-width ${isFullWidth ? "fade-out" : "zero-height"}`}>
                  <figure onClick={() => handleClick()} className={`crs_icn ${isFullWidth ? "fade-in" : ""}`}>
                    <img src="./assets/images/login-landing-cross.svg" alt="logo" />
                  </figure>
                  <div className="top">
                    <span className="heading">PARTNER WITH ESQYRE</span>
                    <span className="sub-heading">
                      Join Our Network Of Luxury Providers
                    </span>
                    <p className="paragraph">
                      At Esqyre, we partner with luxury vehicle vendors who
                      share our passion for quality and exclusivity. Expand your
                      clientele and reach new heights by listing your vehicles
                      with us
                    </p>
                  </div>
                  <div className="tble">
                    <div className="first">
                      <p className="subheading">Access to high-net-worth clients.</p>
                    </div>
                    <div className="second">
                      <p className="top-heading">WHY LIST WITH ESQYRE ?</p>
                      <p>
                        Effortless bookings and management through our vendor
                        portal.
                      </p>
                    </div>
                    <div className="third">
                      <p>Opportunity to join the exclusive Esqyre reserve.</p>
                    </div>
                  </div>
                  <div className="head">
                    <span className="heading">
                      THE VENDOR SWAP PROGRAM - EXPANDING OPPORTUNITIES
                    </span>
                  </div>
                  <div className="grid-group">
                    <div className="grid">
                      <div className="first">
                        <p className="paragraph">
                          Expand your reach beyond your current fleet &
                          Collaborate with other high-end vendors
                        </p>
                      </div>
                      <div className="second">
                        <p className="paragraph">
                          Fullfill specific client request even when your
                          vehicle are booked
                        </p>
                      </div>
                    </div>
                    <div className="grid">
                      <div className="third">
                        <p className="paragraph">
                          Strengthen your reputation as a reliable, full-service
                          provider
                        </p>
                      </div>
                      <div className="fourth">
                        <p className="paragraph">
                          Maximize booking potential and keep your vehicle in
                          demand year-around
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            {/* ) : ( */}
              <>
                {/* <figure
                  className={`logo2_mg ${isFullWidth ? "margin-unset" : ""} ${
                    isVendor ? "vendor" : "corporate"
                  }`}
                  onClick={() => navigate("/")}
                >
                  <img src="./assets/images/logo.svg" alt="logo"></img>
                </figure> */}
                <ul className={`varient-box ${isVendor ? 'vendor': ''} ${isFullWidth ? "zero-height" : "fade-out"}`}>
                  {/* <span className="sub-heading">Why Partner with Us ?</span> */}
                  <li>
                    <figure className="first">
                      <img src="./assets/images/check.svg" alt="logo"></img>
                    </figure>
                    <div>
                      <h2>Access High-Net-Worth Clients</h2>
                      <p>
                        Connect with elite clientele for unmatched growth and
                        visibility.
                      </p>
                    </div>
                  </li>
                  <li>
                    <figure className="scnd">
                      <img src="./assets/images/check.svg" alt="logo"></img>
                    </figure>
                    <div>
                      <h2>Seamless Bookings & Management</h2>
                      <p>
                        Easily manage your business with our all-in-one vendor
                        portal.
                      </p>
                    </div>
                  </li>
                  <li>
                    <figure className="thrd">
                      <img src="./assets/images/check.svg" alt="logo"></img>
                    </figure>
                    <div>
                      <h2>Join the Exclusive Esqyre Reserve</h2>
                      <p>
                        Become part of a prestigious, invitation-only network
                        for top vendors
                        <span className="learn-more" onClick={handleClick}>
                          Learn more
                        </span>
                      </p>
                    </div>
                  </li>
                  <span className="sub-heading pl-20">
                    Elevate Your Business with Us Today!
                  </span>
                </ul>{" "}
                <figure className={`login-ftr-img ${isFullWidth ? "fl-wdth" : "fade-out1"}`}>
                  <img src="./assets/images/car2.png" alt="logo"></img>
                </figure>
              </>
            {/* )} */}
          </>
        ) : (
          <>
            <figure
              className={`logo2_mg ${isFullWidth ? "margin-unset" : ""} ${
                isVendor ? "vendor" : "corporate"
              }`}
              onClick={() => navigate("/")}
            >
              <img src="./assets/images/logo.svg" alt="logo"></img>
            </figure>
            <div className="mobile-res-only">
              <figure onClick={() => navigate("/")} className={`crs_icn ${isFullWidth ? "fade-in" : ""}`}>
                <img src="./assets/images/login-landing-cross.svg" alt="logo" />
              </figure>
              <div className="top">
                <span className="sub-heading">Switch to desktop view for a more optimized user experience</span>
                <p className="paragraph">
                  At Esqyre, we partner with luxury vehicle vendors who
                  share our passion for quality and exclusivity. Expand your
                  clientele and reach new heights by listing your vehicles
                  with us
                </p>
              </div>
            </div>
            <ul className="varient-box">
              {/* <span className="sub-heading">Why Choose ESQYRE ?</span> */}
              <li>
                <figure className="first">
                  <img src="./assets/images/check.svg" alt="logo"></img>
                </figure>
                <div>
                  <h2>Coporate Control</h2>
                  <p>
                    Add and manage employees and clients with ease, ensuring
                    seamless coordination across your organization. Assign
                    roles, permissions, and booking access effortlessly.
                  </p>
                </div>
              </li>
              <li>
                <figure className="scnd">
                  <img src="./assets/images/check.svg" alt="logo"></img>
                </figure>
                <div>
                  <h2>Real-Time Booking</h2>
                  <p>
                    Book vehicles and monitor their status in real time,
                    ensuring that every detail of your luxury experience is
                    handled with precision and efficiency.
                  </p>
                </div>
              </li>
              <li>
                <figure className="thrd">
                  <img src="./assets/images/check.svg" alt="logo"></img>
                </figure>
                <div>
                  <h2>Exclusive Vehicle Access</h2>
                  <p>
                    Unlock premium vehicles reserved exclusively for corporate
                    clients, offering a selection that redefines what it means
                    to travel in style.
                  </p>
                </div>
              </li>
              <span className="sub-heading pl-20">
                Elevate Your Business with Us Today!
              </span>
            </ul>
            <figure className={`login-ftr-img ${isFullWidth ? "fl-wdth" : "fade-out1"}`}>
                  <img src="./assets/images/car2.png" alt="logo"></img>
                </figure>
          </>
        )}
      </div>
    </>
  );
};

export default LoginContent;
