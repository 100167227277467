import React, { useEffect, useState, useRef } from "react";
import "./scrollTabs.scss";

const sections = [
    {
        name: 'Corporate Control', 
        content: [
            {heading: 'Corporate Control', paragraph: 'Seamlessly manage employees and clients within your organization with powerful administrative tools.'},
            {heading: 'Seamless Coordination', paragraph: 'Add, modify, and oversee employees and clients effortlessly. Keep operations streamlined with an intuitive management system.'},
            {heading: 'Smart Access Management', paragraph: 'Assign roles and permissions with precision. Control booking access to ensure secure and efficient usage across your organization.'}
        ],
        imageUrl: './assets/images/corporate-control.png',
        alignText: 'left'
    },
    {
        name: 'Real -Time Booking', 
        content: [
            {heading: 'Real -Time Booking', paragraph: 'Experience seamless luxury travel with instant access to bookings and live status tracking.'},
            {heading: 'Effortless Reservations', paragraph: 'Book vehicles with just a few taps, ensuring a smooth and hassle-free scheduling process.'},
            {heading: 'Live Status Monitoring', paragraph: 'Track your bookings in real time, from dispatch to arrival. Stay informed and in control of every detail.'},
            {heading: 'Precision & Efficiency', paragraph: 'Every aspect of your luxury experience is managed with accuracy, ensuring timely service and unmatched convenience.'}
        ],
        imageUrl: './assets/images/real-time-booking.png',
        alignText: 'right'
    },
    {
        name: 'Integrated KYC', 
        content: [
            {heading: 'Integrated KYC', paragraph: 'Simplify the verification process with integration through UAE Pass, ensuring a hassle-free and secure experience for all users.'},
            {heading: 'On-Platform Verification', paragraph: 'This ensures a fast, secure, and integrated authentication process, enhancing user experience while maintaining compliance and security within the platform.'},
            {heading: 'User Convenience', paragraph: 'Simplifies the process for a hassle-free experience. This enhances user experience, making onboarding and authentication smooth, fast, and stress-free.'}
        ],
        imageUrl: './assets/images/integrated-kyc.png',
        alignText: 'left'
    },
    {
        name: 'Customized Permissions', 
        content: [
            {heading: 'Customized Permissions', paragraph: 'Take full control over who can book and manage luxury services within your organization.'},
            {heading: 'Tailored Access Control', paragraph: 'Role-based permissions ensure users access only relevant information.'},
            {heading: 'Seamless Authorization', paragraph: 'Authorized individuals can efficiently manage their designated roles'},
            {heading: 'Optimized Workflow', paragraph: 'Streamlined processes enhance efficiency while maintaining secure access.'}
        ],
        imageUrl: './assets/images/customised-permissions.png',
        alignText: 'right'
    },
    {
        name: 'Exclusive Vehicle Access', 
        content: [
            {heading: 'Exclusive Vehicle Access', paragraph: 'Ensure that only the right individuals have access to the finest luxury vehicles, tailored to their needs.'},
            {heading: 'VIP & Executive Privileges', paragraph: 'Grant exclusive booking rights to top executives, ensuring priority access to premium vehicles.'},
            {heading: 'Role-Based Access', paragraph: 'Control who can reserve specific vehicle categories, from business-class sedans to high-end limousines.'},
            {heading: 'Precision & Efficiency', paragraph: 'Every aspect of your luxury experience is managed with accuracy, ensuring timely service and unmatched convenience.'}
        ],
        imageUrl: './assets/images/exclusive-vehicle-access.png',
        alignText: 'left'
    }
];

const ScrollTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.current.indexOf(entry.target as HTMLDivElement);
          setActiveTab(index);
        }
      });
    }, observerOptions);

    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  const scrollToSection = (index: number) => {
    const element = sectionRefs.current[index];
    if (element) {
        const yOffset = element.getBoundingClientRect().top + window.scrollY - 150; // Adjust offset as needed
        window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  return (
    <div className="vendor-sec-sec-scrl-tbs">
        <div className="conta_iner">
            {/* Tabs Navigation */}
            <div className="tab-container">
                {sections.map((tab, index) => (
                <div
                    key={index}
                    onClick={() => scrollToSection(index)}
                    className={`tab ${activeTab === index ? "active" : ""}`}
                >
                    <span className="tb-txt">{tab.name}</span>
                </div>
                ))}
            </div>

            {/* Sections */}
            <div className="section-container">
                {sections.map((section, index) => (
                <div
                    key={index}
                    ref={(el) => (sectionRefs.current[index] = el)}
                    className={`section ${section.alignText === 'right' ? "reverse" : ""}`}
                >
                    <div className="txt-container" data-aos="fade-right"
                        data-aos-duration="3000">
                        {section.content.map((content, ind) => (
                            <div className="flx">
                                <span className={`${ind === 0 ? "top-" : ""}heading`}>{content.heading}</span>
                                <span className="paragraph">{content.paragraph}</span>
                            </div>
                        ))}
                    </div>
                    <div className="img-container" data-aos="fade-left"
                        data-aos-duration="3000">
                        <figure>
                            <img src={section.imageUrl} />
                        </figure>
                    </div>
                </div>
                ))}
            </div>
        </div>
    </div>
  );
};

export default ScrollTabs;
