import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import ServiceBanner from "../../../layout/component/service-banner";
import { useLocation } from "react-router-dom";

const AboutContent = () => {
  const [value, setValue] = useState<number>(0);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.scrollTo) {
      const section = document.getElementById(location.state.scrollTo);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const CorporateServiceData = [
    {
      id: 1,
      image: "./assets/images/service1.jpg",
      heading: "Self-Drive Rental",
      description:
        "Empower your team with access to a curated fleet of luxury vehicles. Ideal for business trips, client meetings, or personal travel, our self-drive solutions combine convenience with sophistication.",
    },
    {
      id: 2,
      image: "./assets/images/chauffeur-rental-left.png",
      heading: "Chauffeur Rental",
      description:
        "Our chauffeur services ensure that your clients and executives experience the highest level of luxury and professionalism. From airport transfers to high-profile meetings, Esqyre delivers excellence.",
    },
  ];
  const IndividualServiceData = [
    {
      id: 1,
      image: "./assets/images/indi-self-drive-rantal-right.png",
      heading: "Self-Drive Rentals",
      description:
        "Take control of your journey with our exclusive self-drive rentals. Choose from a selection of high-performance vehicles, including options from our Esqyre Reserve tier, and enjoy the freedom and prestige that comes with driving the finest cars.",
    },
    {
      id: 2,
      image: "./assets/images/service2.jpg",
      heading: "Chauffeur Rental",
      description:
        "Let our professional chauffeurs provide a seamless and sophisticated transportation experience. Perfect for personal occasions where elegance and ease are paramount.",
    },
  ];
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <section className="bg_dark service-tb">
        <div className="conta_iner">
          <div className="sb_hd conta_iner text_white text_center exp_fns_box">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              className="service-second-heading"
            >
              Indulge in the Pinnacle of Luxury Car Services
            </h2>
            <p data-aos="fade-up" data-aos-duration="2000">
              Experience a world where sophistication meets excellence. Our
              premier car services redefine luxury, offering unparalleled
              comfort, style, and precision. Whether for personal or corporate
              needs, every journey with us is a statement of elegance,
              exclusivity, and impeccable service.
            </p>
          </div>
          <div className="faq-accordion">
            <Box className="custom_tabs">
              <Box className="flx_sc">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Corporate Rentals" {...a11yProps(0)} />
                  <Tab label="Individual Rentals" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Box>
          </div>
        </div>
      </section>
      <Box className="tab-content">
        {value === 1 && (
          <>
            {/* Why Esqyre Section */}
            <section className="bg_dark why-section">
              <div className="conta_iner">
                <div className="head">
                  <span className="heading">
                    Luxury Driving, Tailored to You
                  </span>
                  <span className="sub-heading">
                    The freedom to choose, the luxury to indulge.
                  </span>
                </div>
                <p className="paragraph">
                  Esqyre offers unparalleled services for individuals seeking an
                  extraordinary experience. Whether you prefer the thrill of
                  driving yourself or the ease of a professional chauffeur, our
                  services cater to your every need.
                </p>
                <div className="tble">
                  <div className="first">
                    <p>White glove service— start to finish.</p>
                  </div>
                  <div className="second">
                    <p className="top-heading">Why Choose Esqyre?</p>
                    <p>Exclusive options from the Esqyre Reserve.</p>
                  </div>
                  <div className="third">
                    <p>Flexible rental durations to fit your schedule.</p>
                  </div>
                </div>
              </div>
            </section>

            {/* Mapping through IndividualServiceData */}
            {IndividualServiceData?.map((item) => (
              <section
                key={item.id} // Always add a unique "key" when mapping elements
                className={
                  item.id === 2
                    ? "bg_dark service-tb drk-bg-sec_ryt drk-bg-sec"
                    : "bg_dark service-tb drk-bg-sec"
                }
                style={{ backgroundImage: `url('${item?.image}')` }}
              >
                <ServiceBanner
                  id={item?.id}
                  image={item?.image}
                  heading={item?.heading}
                  description={item?.description}
                  type="service"
                />
              </section>
            ))}
            {/* App link Section */}
            <section className="bg_dark why-section app-link">
              <div className="conta_iner">
                <div className="head">
                  <span className="heading">
                    Simple, Efficient Booking Process
                  </span>
                </div>
                <p className="paragraph">
                  Book your luxury vehicle easily through our intuitive app.
                  Select your car, schedule your pickup, and be on your way in
                  minutes.
                </p>
                <div className="download-conatiner head">
                  <figure>
                    <img
                      src="./assets/images/app-store.png"
                      alt="App Store"
                    ></img>
                  </figure>
                  <figure>
                    <img
                      src="./assets/images/play-store.png"
                      alt="Play Store"
                    ></img>
                  </figure>
                </div>
              </div>
            </section>
          </>
        )}

        {value === 0 && (
          <>
            {/* Why Esqyre Section */}
            <section className="bg_dark why-section corporate">
              <div className="conta_iner">
                <div className="head">
                  <span className="heading">
                    Elevate Your Business with Esqyre
                  </span>
                  <span className="sub-heading">
                    Travel Your Executives and Guests in Style
                  </span>
                </div>
                <p className="paragraph">
                  Esqyre’s corporate services deliver tailored luxury solutions
                  for businesses that value efficiency, professionalism, and
                  exclusivity. From executive travel to client transportation,
                  our services redefine corporate mobility.
                </p>
                <div className="tble">
                  <div className="first">
                    <p>Professional, courteous chauffeurs.</p>
                  </div>
                  <div className="second">
                    <p className="top-heading">
                      Why Choose Esqyre Chauffeur Services?
                    </p>
                    <p>Airport pickup and drop-off services.</p>
                  </div>
                  <div className="third">
                    <p>A true white-glove experience from start to finish.</p>
                  </div>
                </div>
              </div>
            </section>
            {CorporateServiceData?.map((item, index) => (
              <section
                id={item?.id == 1 ? "individual" : "corp"}
                className={
                  item.id === 2
                    ? "bg_dark service-tb drk-bg-sec_ryt drk-bg-sec"
                    : "bg_dark service-tb drk-bg-sec"
                }
                style={{
                  backgroundImage: `url('${item?.image}')`,
                }}
              >
                <ServiceBanner
                  key={item?.id}
                  id={item?.id}
                  image={item?.image}
                  heading={item?.heading}
                  description={item?.description}
                  type="service"
                />
              </section>
            ))}
            {/* App link Section */}
            <section className="bg_dark why-section app-link">
              <div className="conta_iner">
                <div className="head">
                  <span className="heading">Reserve Your Chauffeur Today</span>
                </div>
                <p className="paragraph">
                  Book your chauffeur service through our app and experience the
                  ease of true luxury. Our chauffeurs are at your service for
                  airport transfers, events, or any occasion.
                </p>
                <div className="download-conatiner head">
                  <figure>
                    <img
                      src="./assets/images/app-store.png"
                      alt="App Store"
                    ></img>
                  </figure>
                  <figure>
                    <img
                      src="./assets/images/play-store.png"
                      alt="Play Store"
                    ></img>
                  </figure>
                </div>
              </div>
            </section>
          </>
        )}
      </Box>
    </>
  );
};

export default AboutContent;
