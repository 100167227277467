import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollTabs from "./scrollTabs";
const LandingPage = () => {
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    AOS.init({});
    setAnimate(true);
  }, []);
  return (
    <>
      <section className="vendor-first-sec">
        <div className="top">
          <span className="heading">Power at Your Fingertips</span>
          <span className="sub-heading">Elite <span className="light-sbheading">Corporate</span> Travel, Streamlined and Secured</span>
          <p className="paragraph">Esqyre is your gateway to a seamless luxury rental experience. Designed for corporate organizations, our app and portal integrate cutting-edge technology with an intuitive interface, making booking and managing rentals effortless and efficient.</p>
        </div>
        {/* <div className="bg-car">
          <figure>
            <img src="./assets/images/vendor-landing-new.png" />
          </figure>
        </div> */}
      </section>
      <div className="vendor-sec-sec txt">
        <div className="top">
          <div className="white-dot-circle"></div><span className="heading">Key Features of the Corporate POrtals & App</span><div className="white-dot-circle"></div>
        </div>
      </div>
      <ScrollTabs />
      {/* <section className="vendor-sec-sec">
        <div className="top">
          <div className="white-dot-circle"></div><span className="heading">Key Features of the Esqyre App</span><div className="white-dot-circle"></div>
        </div>
        <div className="flx">
          <div className="bg-mobile-left">
            <figure>
              <img src="./assets/images/vendor-landing-2-left.png" />
            </figure>
          </div>
          <div className="right-sec-scrl">
            <div className="box">
              <span className="heading">1. Corporate Control</span>
              <p className="paragraph">Add and manage employees and clients with ease, ensuring seamless coordination across your organization. Assign roles, permissions, and booking access effortlessly.</p>
            </div>
            <div className="box">
              <span className="heading">2. Real-Time Booking</span>
              <p className="paragraph">Book vehicles and monitor their status in real time, ensuring that every detail of your luxury experience is handled with precision and efficiency.</p>
            </div>
            <div className="box">
              <span className="heading">3. Customized Permissions</span>
              <p className="paragraph">Assign specific booking rights to executives and employees based on their roles, ensuring a secure and tailored approach to your corporate travel needs.
              </p>
            </div>
            <div className="box">
              <span className="heading">4. Exclusive Vehicle Access</span>
              <p className="paragraph">Unlock premium vehicles reserved exclusively for corporate clients, offering a selection that redefines what it means to travel in style.
              </p>
            </div>
            <div className="box">
              <span className="heading">5. Integrated KYC</span>
              <p className="paragraph">Simplify the verification process with integration throught UAE Pass, ensuring a hassle-free and secure experience for all users.
              </p>
            </div>
            <div className="box last">
              <span className="heading">6. Online and Desktop Access</span>
              <p className="paragraph">Access the Esqyre platform seamlessly through your desktop or online browser. Manage bookings, track vehciles, and coordinate travel with the same intuitive experience as the app.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="vendor-third-sec">
        <div className="conta_iner">
          <div className="top">
            <span className="heading">How It Works Section</span>
            <span className="sub-heading">Simple, Efficient, and Powerful</span>
          </div>
          <div className="tble">
            <div className="first">
              <p>Log in to the Esqyre app or online platform using your credentials.</p>
            </div>
            <div className="second">
              <p>Select your vehicle and choose between self-drive or chauffeur service.</p>
            </div>
            <div className="third">
              <p>Schedule your booking and enjoy a seamless luxury experience.</p>
            </div>
          </div>
        </div>
        {/* <div className="bg-shade-btm"></div> */}
      </section>
      <section className="application-sec vendor-frth">
        <div className="conta_iner flx-row">
          <div className="left-container" data-aos="fade-up" data-aos-duration="3000">
            <span className="heading">Luxury at Your Fingertips</span>
            <p className="paragraph">
            Download the Esqyre app to easily manage your bookings, explore our fleet, and enjoy complete control over your luxury rental experience. Available on both iOS and Android.
            </p>
            <span className="download-heading">DOWNLOAD NOW</span>
            <div className="download-conatiner">
              <figure>
                <img src="./assets/images/app-store.png" alt="App Store"></img>
              </figure>
              <figure>
                <img src="./assets/images/play-store.png" alt="Play Store"></img>
              </figure>
            </div>
          </div>
          <div className="right-container" data-aos="fade-up" data-aos-duration="3000">
          <figure>
              <img src="./assets/images/vendor-application-group.png" alt="Application Group"></img>
            </figure>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
