import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Applications = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <section className="application-sec">
        <div className="conta_iner flx-row">
          <div className="left-container" data-aos="fade-up" data-aos-duration="3000">
            <span className="heading">Luxury at Your Fingertips</span>
            <p className="paragraph">
            Download the Esqyre app to easily manage your bookings, explore our fleet, and enjoy complete control over your luxury rental experience. Available on both iOS and Android.
            </p>
            <span className="download-heading">DOWNLOAD NOW</span>
            <div className="download-conatiner">
              <figure>
                <img src="./assets/images/app-store.png" alt="App Store"></img>
              </figure>
              <figure>
                <img src="./assets/images/play-store.png" alt="Play Store"></img>
              </figure>
            </div>
          </div>
          <div className="right-container" data-aos="fade-up" data-aos-duration="3000">
          <figure>
              <img src="./assets/images/application-group.png" alt="Application Group"></img>
            </figure>
          </div>
        </div>
      </section>
    </>
  );
};

export default Applications;
